/* Basic editor styles */
.tiptap :first-child {
  margin-top: 0;
}

/* Heading-specific styling */
.tiptap h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.75rem;
}

.tiptap h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.tiptap h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.35rem;
}

/* Table-specific styling */
.tiptap table {
  border-collapse: collapse;
  margin: 0;
  overflow: hidden;
  table-layout: fixed;
  width: 100%;
}

.tiptap table td,
.tiptap table th {
  border: 1px solid var(--chakra-colors-gray-200);
  box-sizing: border-box;
  min-width: 1em;
  padding: 6px 8px;
  position: relative;
  vertical-align: top;
}

.tiptap table td > *,
.tiptap table th > * {
  margin-bottom: 0;
}

.tiptap table th {
  background-color: var(--chakra-colors-gray-100);
  font-weight: bold;
  text-align: left;
}

.tiptap table .selectedCell:after {
  background-color: var(--chakra-colors-gray-100);
  opacity: 0.5;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  position: absolute;
  z-index: 2;
}

.tiptap table .column-resize-handle {
  background-color: var(--chakra-colors-purple-500);
  bottom: -2px;
  pointer-events: none;
  position: absolute;
  right: -2px;
  top: 0;
  width: 4px;
}

.tiptap .tableWrapper {
  margin: 1.5rem 0;
  overflow-x: auto;
}

.tiptap.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.tiptap a {
  color: var(--chakra-colors-blue-500);
  text-decoration: underline;
  cursor: pointer;
}

.tiptap p {
  margin-bottom: 0.75rem;
}
